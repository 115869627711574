html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.mat-dialog-container {
  background: none !important;
  box-shadow: none !important;
}

.cdk-global-overlay-wrapper {
  background-color: rgba($color: map_get($md-primary, 900), $alpha: .1);
}

.mat-form-field-outline > * {
  color: map_get($md-primary, 100);

  &:focus {
    font-weight: 100;
  }
}

mat-form-field {
  --mat-form-field-container-height: 33px;

  margin: 5px 0;
  height: 2rem;
  font-size: 10px;
  border: solid 1px map_get($md-primary, 200);
  border-radius: map_get($radius, 2);

  ::ng-deep.mat-mdc-text-field-wrapper {
    padding-bottom: 0;
    background-color: map_get($md-grey, A100);
    border-radius: map_get($radius, 2);
    border: none !important;

    .mat-mdc-form-field-infix {
      padding: 0.7em 0;
    }

    .mat-mdc-form-field-flex {
      height: calc(2rem - 2px);
      font-size: 10px;
      border: none !important;

      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: transparent !important;
      }

      .mdc-notched-outline {
        border: none !important;
      }

      label {
        top: 45%;

        mat-label {
          --lateral-distance: 10px;
          font-size: 10px;
          padding: 3px var(--lateral-distance) 0 var(--lateral-distance);
          color: map-get($md-grey, 850);
          background-color: map-get($md-grey, A100);
        }
      }
    }
  }

  input {
    color: map_get($md-grey, 850);
  }
}

.mat-button-focus-overlay {
  background-color: transparent !important;
  color: transparent !important;
}
