//@import "mixing";
@import "libs/shared/src/theme";

$fa-font-path: "../../../node_modules/font-awesome/fonts";
@import 'font-awesome/scss/font-awesome';

// loading oxygen font
@font-face {
  font-family: "Oxygen";
  src: url(./assets/Fira_Code/FiraCode-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Oxygen";
  src: url(assets/Fira_Code/FiraCode-Light.ttf) format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Oxygen";
  src: url(assets/Fira_Code/FiraCode-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Oxygen";
  src: url(assets/Fira_Code/FiraCode-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

* {
  font-family: 'Oxygen', 'Droid Sans Mono', monospace;
}

markdown {
  color: map_get($md-grey, 800);

  h1,
  h2,
  h4,
  h5,
  h6 {
    font-weight: 300;
  }

  h3 {
    font-weight: bold;
  }

  p {
    font-size: 15px;
    font-weight: 100;
  }

  iframe {
    max-width: 100%;
  }

  pre {
    font-size: 18px;
    font-weight: 100;
    background-color: map_get($md-grey, A100) !important;
    box-shadow: map_get($box-shadow, 0);
    border: 1px solid map_get($md-primary, 100);

    code {
      color: map_get($md-grey, 900) !important;
      padding: 0 3px;
      border-radius: map_get($radius, 2);
    }
  }

  p code, li code {
    background: #00000014;
    color: #706060;
    padding: 0 1px;
  }

  ul {
    font-size: 15px;
    font-weight: 100;

    li {
      &::marker {
        color: map-get($md-primary, 500);
        content: '○ ';
      }
    }
  }

  a {
    color: map_get($md-grey, 800);
    word-break: break-all;
  }

  strong {
    font-weight: bold;
  }

  blockquote {
    position: relative;
    margin: map-get($spacings, 1) 0;
    padding: map-get($spacings, 1) map-get($spacings, 2) map-get($spacings, 1) map-get($spacings, 6);
    background-color: map-get($md-grey, 49);
    border-radius: map-get($radius, 3);
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 6px;
      background-color: map_get($md-primary, 500);
    }
  }

  .image-container {
    background-color: transparent !important;
    overflow-x: hidden;

    img {
      width: 50%;
      object-fit: cover;
      object-position: center;
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }

  img {
    max-width: 100%;
    box-shadow: map_get($box-shadow, 0);
    border-radius: map_get($radius, 2) !important;
  }
}


//body::-webkit-scrollbar-track {
//  border-radius: 10px;
//  background: rgba(0,0,0,0.1);
//  border: 1px solid #ccc;
//}
//
//body::-webkit-scrollbar-thumb {
//  border-radius: 10px;
//  background: linear-gradient(left, #fff, #e4e4e4);
//  border: 1px solid #aaa;
//}
//
//body::-webkit-scrollbar-thumb:hover {
//  background: #fff;
//}
//
//body::-webkit-scrollbar-thumb:active {
//  background: linear-gradient(left, #22ADD4, #1E98BA);
//}
