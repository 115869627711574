@tailwind base;
@tailwind components;
@tailwind utilities;

$font-family: 'Droid Sans Mono',
'monospace',
monospace,
'Droid Sans Fallback';

$spacings: (0 : 0,
  1 : 6px,
  2 : 12px,
  3 : 18px,
  4 : 24px,
  5 : 30px,
  6 : 36px,
  7 : 42px,
  8 : 48px,
  9 : 54px,
  10: 60px);

$radius: (0:0,
  1 : 2px,
  2 : 4px,
  3 : 6px,
  4 : 8px,
  5 : 10px,
  6 : 12px,
  7 : 14px,
  8 : 16px,
  9 : 18px,
  10: 20px);

$time: (0:0s,
  1 : .2s,
  2 : .4s,
  3 : .6s,
  4 : .8s,
  5 : 1s,
  6 : 1.2s,
  7 : 1.4s,
  8 : 1.6s,
  9 : 1.8s,
  10: 2s);

$md-secundary: (50 : rgb(247, 236, 249),
  100 : #edcff0,
  200 : #e1afe6,
  300 : #d28edb,
  400 : #c976d4,
  500 : #8e01b1,
  600 : #c756c1,
  700 : #c04cb6,
  800 : #b942b3,
  900 : #cd01cd,
  A100 : #fdf4ff,
  A200 : #e1a7fc,
  A400 : #cd61ff,
  A700 : #a200ff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ));

$md-primary: (50 : #ecf9f5,
  100 : #cff0e5,
  200 : #afe6d4,
  300 : #8edbc3,
  400 : #76d4b6,
  500 : #19c65e,
  600 : #56c7a2,
  700 : #4cc098,
  800 : #42b98f,
  900 : #01cd6a,
  910 : #0a9240,
  A100 : #f4fffb,
  A200 : #c1ffe6,
  A400 : #8effd2,
  A700 : #74ffc8,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ));

$md-error: (50 : #f9ecec,
  100 : #f0cfcf,
  200 : #e6afaf,
  300 : #db8e8e,
  400 : #d47676,
  500 : #b10101,
  600 : #c75656,
  700 : #c04c4c,
  800 : #b94242,
  900 : #cd0101,
  A100 : #fff4f4,
  A200 : #ffc1c1,
  A400 : #ff8e8e,
  A700 : #ff7474,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ));


$md-grey: (
  48 : #fafafa,
  49 : #f6f6f6,
  50 : #e9ebf2,
  100 : #fefefe,
  200 : #fdfdfd,
  300 : #fcfcfc,
  350 : #f9f9f9,
  400 : #f3f3f3,
  450 : #dadada,
  500 : #c2c2c2,
  600 : #959595,
  700 : #616161,
  800 : #4d4d4d,
  850 : #292929,
  900 : #000000,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ));

$box-shadow: (0 : 0,
  1 : -1px 6px 2px 0 rgba(101, 109, 120, 0.2),
  2 : -1px 6px 6px 0 rgba(101, 109, 120, 0.2),
  3 : -1px 6px 10px 0 rgba(101, 109, 120, 0.2),
  4 : -1px 6px 14px 0 rgba(101, 109, 120, 0.2), // recomended
  5 : -1px 6px 18px 0 rgba(101, 109, 120, 0.2),
  6 : -1px 6px 22px 0 rgba(101, 109, 120, 0.2));
$box-shadow-up: (0: 0,
  1 : 1px 6px 2px 0 rgba(101, 109, 120, 0.2),
  2 : 1px 6px 6px 0 rgba(101, 109, 120, 0.2),
  3 : 1px 6px 10px 0 rgba(101, 109, 120, 0.2),
  4 : 1px 6px 14px 0 rgba(101, 109, 120, 0.2), // recomended
  5 : 1px 6px 18px 0 rgba(101, 109, 120, 0.2),
  6 : 1px 6px 22px 0 rgba(101, 109, 120, 0.2));

@keyframes loading-animation {
  from {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@import './theme-reset.scss';
@import './theme-bc-class.scss';

// legal markdown style
.markdown-post-style {
  p, h1, h2, h4, h5, h6, ul {
    display: block;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  h1 {
    font-size: 2em;
    font-weight: 300;
    margin-block-start: 0.67rem;
    margin-block-end: 0.67rem;
  }

  h2 {
    font-size: 1.5em;
    font-weight: 300;
    margin-block-start: 0.83rem;
    margin-block-end: 0.83rem;
  }

  h3 {
    font-size: 1.17em;
    font-weight: 300;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
  }

  p {
    font-size: 14px;
    font-weight: 100;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  a {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 100;

    &:hover {
      font-weight: bold;
    }
  }

  ul {
    font-size: 14px;
    font-weight: 100;
    margin-block-start: 1em;
    margin-block-end: 1em;
    padding-inline-start: 40px;
  }
}

// legal markdown style
.markdown-legal-style {
  p, h1, h2, h4, h5, h6, ul {
    display: block;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  h1 {
    font-size: 2em;
    font-weight: 300;
    margin-block-start: 0.67rem;
    margin-block-end: 0.67rem;
  }

  h2 {
    font-size: 1.5em;
    font-weight: 300;
    margin-block-start: 0.83rem;
    margin-block-end: 0.83rem;
  }

  h3 {
    font-size: 1.17em;
    font-weight: 300;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
  }

  p {
    font-size: 14px;
    font-weight: 100;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  a {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 100;

    &:hover {
      font-weight: bold;
    }
  }

  ul {
    font-size: 14px;
    font-weight: 100;
    margin-block-start: 1em;
    margin-block-end: 1em;
    padding-inline-start: 40px;
  }
}
